<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="0" md="2" lg="4"> </v-col>
      <v-col cols="12" md="8" lg="4">
        <v-card style="border-radius: 8px; box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;">
          <v-app-bar flat color="transparent">

            <v-spacer></v-spacer>

            <v-menu offset-y v-if="langs.length && langs.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-3" v-bind="attrs" v-on="on">
                  <v-icon>mdi-web</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item v-for="(l, index) in langs" :key="index" @click="() => switchLang(l.tblCnfIdioma.codigo)">
                  <v-list-item-title class="text-uppercase">{{ l.tblCnfIdioma.codigo }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" fab small class="mr-3" color="grey lighten-2" v-bind="attrs" v-on="on">
                  <div class="font-weight-bold">{{ $i18n.locale }}</div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in langs" :key="index" @click="() => switchLang(item.code)">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-app-bar>

          <v-card-text class="black--text" v-if="success">
            <div class="text-center my-10">
              <h3 class="mb-3">{{ $t('Signup.success') }}</h3>
              <h5 class="text-sm font-600 grey--text text--darken-4">
                {{ $t('Signup.checkEmail') }}
              </h5>
            </div>
          </v-card-text>

          <v-card-text class="black--text" v-else>
            <v-form ref="accountForm" class="px-5" :disabled="loading">
              <div class="text-center mb-10">
                <h3 class="my-3">{{ $t("Signup.createAccount") }}</h3>
                <h5 class="text-sm font-600 grey--text text--darken-4">
                  {{ $t("Signup.instructions") }}
                </h5>
              </div>

              <div>
                <p class="text-14 mb-1">{{ $t("Signup.firstname") }}</p>
                <v-text-field v-model="account.nombre" outlined dense :rules="[$sv.required]" />
              </div>

              <div>
                <p class="text-14 mb-1">{{ $t("Signup.lastname") }}</p>
                <v-text-field v-model="account.apellidos" outlined dense :rules="[$sv.required]" />
              </div>

              <div>
                <p class="text-14 mb-1">{{ $t("Signup.email") }}</p>
                <v-text-field v-model="account.mail" outlined dense :rules="[$sv.required, $sv.email]" />
              </div>

              <div>
                <p class="text-14 mb-1">{{ $t("Signup.phone") }}</p>
                <v-text-field v-model="account.telefono" outlined dense :rules="[$sv.phone]" />
              </div>

              <div class="pb-4">
                <v-checkbox v-model="checkbox" :rules="[$sv.isChecked]">

                  <template v-slot:label>
                    <div>
                      <i18n path="Signup.term" tag="label" for="tos">
                        <router-link :to="`/entities/${$route.params.entidadId}/terms`" v-text="$t('Signup.tos')"></router-link>
                      </i18n>
                    </div>
                  </template>
                </v-checkbox>
              </div>

              <div class="mb-7">
                <v-btn block color="primary" class="text-capitalize font-600" @click="() => signup()"
                  :disabled="loading" :loading="loading">
                  {{ $t("Signup.create") }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>

          <v-card-text class="py-4 text-center grey lighten-2">
            <div class="grey--text text--darken-1">
              {{ $t("Signup.alreadyHave") }}

              <router-link :to="`/entities/${$route.params.entidadId}/signin`" v-text="$t('Signup.login')"></router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="0" md="2" lg="4"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  watch: {
    '$route.params.entidadId': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.fetchLanguages()
        }
      },
    },
  },
  data() {
    return {
      checkbox: false,
      account: {},
      loading: false,
      success: false,
      langs: []
    };
  },
  methods: {
    fetchLanguages() {
      const entidadId = this.$route.params.entidadId;

      axios.get(`api/v1/entities/${entidadId}/langs`)
        .then((res) => {
          const langs = _.get(res, 'data.data', []);

          langs.map((l) => {
            
            if (l.isDefault) {
              const defaultLang = l.tblCnfIdioma.archivo;

              this.switchLang(defaultLang);
            }
          });

          this.langs = langs;
        })
        .catch(() => {
          this.langs = [];
        });
    },
    signup() {
      let success = this.$refs.accountForm.validate();

      if (success && this.checkbox) {
        this.loading = true;

        const entidadId = this.$route.params.entidadId;

        if (entidadId) {
          this.account.entidadId = entidadId;
        }

        this.success = false;

        this.$store
          .dispatch("authentication/signup", this.account)
          .then(() => {
            this.success = true;
            this.loading = false;
          })
          .catch((err) => {
            this.$dialog({
              title: "Error",
              text: err,
            });
            this.success = false;
            this.loading = false;
          });
      }
    },
    switchLang(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>